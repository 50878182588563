import { InputHTMLAttributes } from 'react';
import styles from './styles.module.scss';
import { noop, omit } from 'lodash';
import { RefCallBack } from 'react-hook-form';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  inputRef?: RefCallBack; // this should be mandatory if onChange prop is not provided.
  onChange?: typeof noop; // this should be mandatory if inputRef prop is not provided.
  error?: string;
  isDisabled?: boolean;
  hasValue?: boolean;
  endSlot?: React.ReactNode;
}

export default function Input(props: InputProps) {
  const {
    error = '',
    label = '',
    hasValue = false,
    isDisabled = false,
    onChange = noop,
  } = props;

  return (
    <div className={styles.container}>
      <div
        className={`${styles.wrapper} ${error ? styles.error : ''}  ${
          hasValue ? styles.isDirty : ''
        }  
          `}
      >
        {label ? <label>{label}</label> : null}
        <div className={`${styles.inputWrapper}`}>
          <input
            disabled={isDisabled}
            tabIndex={0}
            ref={props.inputRef}
            onChange={onChange}
            style={{ paddingRight: props.endSlot ? '40px' : undefined }}
            {...omit(props, [
              'inputRef',
              'hasValue',
              'error',
              'isDisabled',
              'endSlot',
            ])}
          />
          {props.endSlot && (
            <div className={styles.endSlotContainer}>{props.endSlot}</div>
          )}
          <div className={styles.wrapperHelper}>
            <div className={styles.onFocusBorder} />
          </div>
        </div>
      </div>
      {error ? <p>{error}</p> : null}
    </div>
  );
}

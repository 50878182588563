/* eslint-disable @typescript-eslint/no-unused-vars */
import Input from '@components/Input';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';

type Props = {
  userData: any;
};

export default function Credentials({ userData }: Props) {
  const { t } = useTranslation('dashboard');
  const [showPassword, setShowPassword] = useState(false);
  const [showUsername, setShowUsername] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <fieldset>
          <Input
            label={t('credentials.username')}
            type={showUsername ? 'text' : 'password'}
            disabled
            value={userData.credentials_username}
            endSlot={
              <FeatherIcon
                strokeWidth="2px"
                icon={showUsername ? 'eye' : 'eye-off'}
                color={'var(--neutral400)'}
                size="20"
                onClick={() => setShowUsername(!showUsername)}
              />
            }
          />
        </fieldset>
        <fieldset>
          <Input
            label={t('credentials.password')}
            type={showPassword ? 'text' : 'password'}
            disabled
            value={userData.credentials_password}
            endSlot={
              <FeatherIcon
                strokeWidth="2px"
                icon={showPassword ? 'eye' : 'eye-off'}
                color={'var(--neutral400)'}
                size="20"
                onClick={() => setShowPassword(!showPassword)}
              />
            }
          />
        </fieldset>
      </div>
    </>
  );
}
